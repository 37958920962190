.Thank-you {
  padding-top: 3em;
  padding-bottom: 3em;
}

.text-2xl {
  font-size: 2.0rem;
  font-weight: 600;
  line-height: 2.5rem;
}

.text-md {
  font-size: 1.5rem;
  line-height: 2.5rem;
}


.ContactForm {
  padding-left: 5px;
  padding-right: 5px;
}

.ContactForm-submit {
  background-color: #00c2cb !important;
  border-color: #00c2cb !important;
  color: #eee;
  font-size: 16px;
  height: 50px;
  width: 50%;
}

@media only screen and ((max-width: 700px) or (orientation:portrait)) {
  .ContactForm {
    padding-left: 10px;
    padding-right: 10px;
  }
}

