.App {
  text-align: center;
  background-color: rgb(22,22,22);
}


.App-main {
  background-color: #282c34;
  min-height: calc(100vh - 66px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  font-family: 'Manrope, sans-serif';
  color: white;
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-logo {
  height: 40px;
  margin-right: 10px;
  pointer-events: none;
}

.App-header-image-container {
  flex: none;
}

.App-header-image {
  width: 40vw;
}

.App-header-title {
  flex: none;
}

.App-header-title h1 {
  color: #eee;
  font-size: 46px;
  font-variant-ligatures: common-ligatures;
  font-weight: 600;
  text-align: center;
  margin-top: 20px;
}

.App-contact h2 {
  color: #eee;
  font-size: 36px;
  font-variant-ligatures: common-ligatures;
  font-weight: 600;
}

@media only screen and ((max-width: 700px) or (orientation:portrait)) {
  .App-header-image {
    width: 80vw;
  }
  .App-header-title h1 {
    font-size: 34px;
    margin-bottom: 45px;
  }
  .App-contact h2 {
    font-size: 26px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media only screen and (orientation:portrait) {
  div[class^='col-md'], div[class*='col-md']{
    width: 100%;
  }
  div[class^='offset-md'], div[class*='offset-md']{
    margin-left: 0;
  }
  .row {
    margin-left: 0;
    margin-right: 0;
  }
  .container {
    max-width: 100%;
  }
}
