.Nav-bar {
    padding-left: 20px;
    padding-right: 20px;
}

.company-name {
    font-family: 'Manrope, sans-serif';
    color: #eee;
    font-size: 18px;
    font-weight: 500;
}
  